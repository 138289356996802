import { GridItemProps } from '@rsa-digital/evo-shared-components/components/Grid';
import useValidation from '@rsa-digital/evo-shared-components/helpers/forms/useValidation';
import { isAggsQuote } from 'businessLogic/aggregators';
import { isYoungestPetUnder8Weeks } from 'businessLogic/petAge';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import CoverStartDate from 'components/CoverStartDate';
import FormFooter from 'components/FormFooter';
import RichTextWithModal from 'components/RichTextWithModal';
import {
  PageTitle,
  trackFieldError,
  trackRichTextLinkClick,
  trackTextButtonClick,
} from 'helpers/eventTracking';
import { scrollAndTrackError } from 'helpers/forms';
import {
  combinePetNames,
  petNameReplacer,
} from 'helpers/placeholders/dynamicPetNameHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { usePetsDetails } from 'state/formData/petsDetails';
import { usePolicyDetails } from 'state/formData/policyDetails';
import MarketingConsentSection from './MarketingConsentSection';
import useMarketingConsentRules from './MarketingConsentSection/validation';
import useAboutYourPolicyQuestions from './questions';
import {
  HeadingMedium,
  ParagraphLargeWithMargins,
  PrivacyPolicy,
  PrivacyPolicyHeading,
} from './styles';

type AboutYourPolicyFormProps = {
  moveNext: () => void;
  moveBack: () => void;
  gridItemProps?: GridItemProps;
};

type AboutYourPolicyFormData = {
  csPetAboutYourPolicy: {
    next_button_text: string;
    privacy_policy_section_heading: string;
    privacy_policy: string;
    cover_start_date: {
      heading: string;
      important_information: string;
    };
  };
};

export const query = graphql`
  query {
    csPetAboutYourPolicy {
      next_button_text
      privacy_policy_section_heading
      privacy_policy
      cover_start_date {
        heading
        important_information
      }
    }
  }
`;

const AboutYourPolicyForm: React.FC<AboutYourPolicyFormProps> = ({
  moveNext,
  moveBack,
  gridItemProps,
}) => {
  const {
    csPetAboutYourPolicy: {
      next_button_text,
      privacy_policy,
      privacy_policy_section_heading,
      cover_start_date: { heading, important_information },
    },
  } = useStaticQuery<AboutYourPolicyFormData>(query);

  const quote = useCurrentQuote();
  const aggsQuote = isAggsQuote(quote);

  const questions = useAboutYourPolicyQuestions();
  const [petsDetails] = usePetsDetails();
  const [policyDetails] = usePolicyDetails();
  const marketingConsentRules = useMarketingConsentRules();

  const privacyPolicySectionId = 'privacy-policy-section';

  const { getError, validateOnSubmit } = useValidation(
    policyDetails,
    !isAggsQuote(quote) ? marketingConsentRules : {},
    trackFieldError
  );

  const petNames = petsDetails.map((pet) => pet.petName);
  const combinedPetNames = combinePetNames(petNames ?? []);

  const youngPetExplanatoryText = petNameReplacer(
    combinedPetNames,
    questions.coverStartDate.youngPetExplanatoryText ?? ''
  );

  return (
    <form onSubmit={validateOnSubmit(moveNext, scrollAndTrackError)}>
      <HeadingMedium>{heading}</HeadingMedium>
      <RichTextWithModal
        html={important_information}
        pageTitle={PageTitle.AboutYourPolicy}
      />
      {isYoungestPetUnder8Weeks(petsDetails) && (
        <ParagraphLargeWithMargins id="young-pet-is-under-8-weeks-text">
          {youngPetExplanatoryText}
        </ParagraphLargeWithMargins>
      )}
      <CoverStartDate />
      {!aggsQuote && (
        <MarketingConsentSection
          getError={getError}
          pageTitle={PageTitle.AboutYourPolicy}
          gridItemProps={gridItemProps}
        />
      )}
      <section aria-labelledby={privacyPolicySectionId}>
        <PrivacyPolicyHeading id={privacyPolicySectionId}>
          {privacy_policy_section_heading}
        </PrivacyPolicyHeading>
        <PrivacyPolicy
          pageTitle={PageTitle.AboutYourPolicy}
          html={privacy_policy}
          onLinkClick={trackRichTextLinkClick(PageTitle.AboutYourPolicy)}
        />
      </section>
      <FormFooter
        moveNextButton={{
          text: next_button_text,
          onClick: () =>
            trackTextButtonClick(PageTitle.AboutYourPolicy, 'Submit details'),
        }}
        backButton={{
          onClick: () => {
            moveBack();
            trackTextButtonClick(PageTitle.AboutYourPolicy, 'Move back');
          },
        }}
        pageTitle={PageTitle.AboutYourPolicy}
      />
    </form>
  );
};

export default AboutYourPolicyForm;
